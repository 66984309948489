/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const cartConstants = {
  online: 'online',
  store: 'store',
  STOREORDER: 'STOREORDER',
  ONLINEORDER: 'ONLINEORDER',
  INSTORE: 'INSTORE',
  INSTOREPURCHASE: 'InStore',
  INSTORERETURN: 'RETURN',
  ORDERMESSAGE: 'Order by 10pm for',
  STH: 'STH',
  BOPUS: 'BOPUS',
  SDD: 'SDD',
  freebie: 'freebie',
  giftCard: 'giftCard',
  checkout: 'checkout',
  quantity: 'quantity',
  none: 'None',
  remove: 'Remove',
  add: 'Add',
  INCOMPLETE: 'INCOMPLETE',
  undefined: 'undefined',
  function: 'function',
  componentDidMountSuccess: 'Component Did Mount Succesfully in Cart Page',
  info: 'info',
  CART_PAGE: 'CART_PAGE',
  productDetails: 'productDetails',
} as const;
